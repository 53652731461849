<template>
  <div class="warehouse-double-list-page">
    <el-form ref="dataForm" inline class="bysearchForm">
      <div class="searchLine">
        <el-form-item prop="periodId" class="typeInput" label="时段">
          <el-select
            v-model="dataForm.periodId"
            placeholder="时段"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="freezerIds" class="typeInput" label="取餐柜">
          <el-select
            v-model="dataForm.freezerIds"
            placeholder="取餐柜"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in freezerList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="week" class="typeInput" label="星期">
          <el-select
            v-model="dataForm.week"
            placeholder="星期"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.week"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="states" label="状态">
          <el-select
            v-model="dataForm.states"
            placeholder="状态"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="isGroup" label="是否团餐柜">
          <el-select
            v-model="dataForm.isGroup"
            placeholder="是否团餐柜"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.isGroup"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDetailList(null, 1)">查询</el-button>
          <el-button
            v-if="isAuth('tc:orgPeriodFreezer:save')"
            type="primary"
            @click="$dialog('addOrUpdate', null, false)"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:orgPeriodFreezer:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="detailListSelections.length <= 0"
            >批量删除</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="content-container">
      <div class="profit-loss-list-container" style="flex: 0 0 25%">
        <div class="table-container">
          <el-table
            height="calc(100vh - 210px)"
            :data="dataList"
            ref="dataList"
            border
            :row-class-name="tableRowClassName"
            @selection-change="$selectionChange($event, 'dataList')"
            @row-click="
              $clickRow(
                $event,
                'detailList',
                getDetailList($event, 1),
                colorChange($event.id),
              )
            "
            @select="$select(arguments, 'dataList')"
            @select-all="$clearSelection('dataList')"
          >
            <el-table-column
              prop="name"
              header-align="center"
              align="center"
              label="供应商"
            />
          </el-table>
        </div>
        <div class="pagination-container">
          <el-pagination
            background
            @size-change="$sizeChange($event, 'dataList')"
            @current-change="$currentChange($event, 'dataList')"
            :current-page="dataListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="dataListSize"
            :total="dataListCount"
            layout="total, prev, pager, next"
          />
        </div>
      </div>
      <div class="divider-border" />
      <div class="profit-loss-detail-container">
        <div class="table-container">
          <el-table
            :data="detailList"
            border
            stripe
            ref="detailList"
            v-loading="detailListLoading"
            @row-click="$clickRow($event, 'detailList')"
            @selection-change="$selectionChange($event, 'detailList')"
            style="width: 100%"
            @select-all="handleSelectAll"
          >
            <el-table-column
              type="selection"
              header-align="center"
              align="center"
              width="50"
            />
            <el-table-column
              prop="orgName"
              header-align="center"
              align="center"
              label="供应商名称"
            />
            <el-table-column
              prop="freezerName"
              header-align="center"
              align="center"
              label="取餐柜名称"
            />
            <el-table-column
              prop="isGroup"
              header-align="center"
              align="center"
              label="是否团餐柜"
            >
              <template slot-scope="scope">
                <el-tag :type="scope.row.isGroup ? 'success' : 'danger'">
                  {{ scope.row.isGroup ? '是' : '否' }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="periodWithTimeName"
              header-align="center"
              align="center"
              label="时段"
            />
            <el-table-column
              prop="chineseWeeks"
              header-align="center"
              align="center"
              label="星期"
            />
            <el-table-column
              prop="state"
              header-align="center"
              align="center"
              width="120"
              label="状态"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.state === 0" size="small"
                  >未生效</el-tag
                >
                <el-tag v-else-if="scope.row.state === 1" size="small"
                  >生效中</el-tag
                >
                <el-tag v-else-if="scope.row.state === 2" size="small"
                  >已失效</el-tag
                >
                <el-tag v-else-if="scope.row.state === 3" size="small"
                  >已终止</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="createdAt"
              header-align="center"
              align="center"
              label="创建日期"
            />

            <el-table-column
              prop="createdBy"
              header-align="center"
              align="center"
              label="创建者"
            />
            <el-table-column
              header-align="center"
              align="center"
              width="280"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="isAuth('tc:orgPeriodFreezer:info')"
                  type="text"
                  size="small"
                  @click="$dialog('addOrUpdate', scope.row.id, true)"
                  >查看</el-button
                >
                <el-button
                  v-if="
                    isAuth('tc:orgPeriodFreezer:update') &&
                    scope.row.state === 0
                  "
                  type="text"
                  size="small"
                  @click="$dialog('addOrUpdate', scope.row.id, false)"
                  >修改</el-button
                >
                <el-button
                  v-if="isAuth('tc:orgPeriodFreezer:delete')"
                  type="text"
                  size="small"
                  @click="deleteHandle(scope.row.id)"
                  >删除</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  v-if="isAuth('tc:periodFreezerShelf:freezerShelf')"
                  @click="
                    $dialog(
                      'freezerShelf',
                      scope.row.id,
                      scope.row.periodId,
                      scope.row.periodWithTimeName,
                      scope.row.freezerId,
                      scope.row.freezerName,
                      scope.row.specId,
                      true,
                    )
                  "
                  >取餐柜中转架</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  v-if="isAuth('tc:orgPeriodFreezer:spaceInfo')"
                  @click="$dialog('freezerCategory', scope.row)"
                >
                  仓位管理
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-container">
          <el-pagination
            background
            @size-change="$sizeChange($event, 'detailList')"
            @current-change="$currentChange($event, 'detailList')"
            :current-page="detailListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="detailListSize"
            :total="detailListCount"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </div>
      </div>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDetailList(null, 1)"
    />
    <freezer-shelf
      v-if="freezerShelfVisible"
      ref="freezerShelf"
      @success="getDataList"
    />
    <freezerCategory
      v-if="freezerCategoryVisible"
      ref="freezerCategory"
      @refreshDataList="getDataList()"
    />
    <freezer-goods-on-shelves
      v-if="freezerGoodsOnShelvesVisible"
      ref="freezerGoodsOnShelves"
      @success="getDetailList(null, 1)"
    />
  </div>
</template>

<script>
import FreezerShelf from '@/views/modules/freezer/freezer-shlef';
import FreezerCategory from '@/views/modules/freezer/freezer-category-add-or-update';
import FreezerGoodsOnShelves from './freezer-goods-on-shelves';
import { threeListMixin, listMixin, normal } from '@/mixins';
import AddOrUpdate from './org-period-freezer-add-or-update';
import { getPeriodList, getOrgList, getFreezerList } from '@/utils/options.js';
export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      dataForm: {
        orgId: '',
        periodId: '',
        freezerIds: [],
        week: '',
        states: [0, 1],
        isGroup: '',
      },
      ids: '',
      orgName: '',
      periodTable: '',
      orgIds: [],
      chooseIndex: 0,
      dataList: [],
      detailList: [],
      addOrUpdateVisible: false,
      freezerShelfVisible: false,
      freezerCategoryVisible: false,
      freezerGoodsOnShelvesVisible: false,
      dataListSelections: [],
      detailListSelections: [],
      orgList: [],
      periodList: [],
      freezerList: [],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      stateList: [
        { id: 0, state: '未生效' },
        { id: 1, state: '生效中' },
        { id: 2, state: '已失效' },
        { id: 3, state: '已终止' },
      ],
      groupList: [
        { id: 0, isGroup: '否' },
        { id: 1, isGroup: '是' },
      ],
    };
  },
  components: {
    AddOrUpdate,
    FreezerShelf,
    FreezerCategory,
    FreezerGoodsOnShelves,
  },
  activated() {
    this.getDataList();
  },
  created() {
    this.getPeriodList();
    this.getOrgList();
    this.getFreezerList();
  },
  methods: {
    tableRowClassName({ row }) {
      // this.
      if (row.id === this.chooseIndex) {
        return 'danger-row';
      } else {
        return '';
      }
    },
    colorChange(id) {
      this.chooseIndex = id;
    },
    getDataList() {
      this.$http({
        url: `/cc/org/page`,
        method: 'get',
        params: {
          type: 0,
          isUse: 1,
          page: this.dataListIndex,
          limit: this.dataListSize,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.data.items;
          this.dataListCount = data.data.total;
          this.dataForm.orgId = data.data.items[0].id;
          this.colorChange(this.dataForm.orgId);
          this.getDetailList(null, 1);
        } else {
          this.dataList = [];
          this.dataListCount = 0;
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    // 获取数据列表
    getDetailList(row, page) {
      this.dataForm.orgId = row ? row.id : this.dataForm.orgId;
      this.detailListIndex = page ? page : this.detailListIndex;
      this.$api({
        url: '/tc/orgPeriodFreezer/list',
        params: {
          page: this.detailListIndex,
          limit: this.detailListSize,
          orgId: this.dataForm.orgId,
          periodId: this.dataForm.periodId,
          freezerIds: this.dataForm.freezerIds.join(','),
          week: this.dataForm.week,
          isGroup: this.dataForm.isGroup,
          states: this.dataForm.states.join(','),
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.detailList = data.page.records;
            this.detailListCount = data.page.total;
          } else {
            this.dataList = [];
            this.detailListCount = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.detailListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否确认对这些供应商-时段-冷柜进行删除操作?`,
        url: '/tc/orgPeriodFreezer/delete',
        data: ids,
        after: () => {
          this.getDetailList(null, 1);
        },
      });
    },
    getPeriodList() {
      getPeriodList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
    getFreezerList() {
      getFreezerList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
        }
      });
    },
    // 批量上架商品
    updateGoods(row) {
      this.getPeriodTable(row);
      if (this.orgIds?.length) {
        this.$message.error(`请选择相同的供应商进行批量操作`);
      } else {
        this.$dialog(
          'freezerGoodsOnShelves',
          this.dataForm.orgId,
          this.ids,
          this.orgName,
          this.periodTable,
        );
      }
    },
    getPeriodTable(row) {
      this.ids = row
        ? row.id
        : this.detailListSelections
            .map((item) => {
              return item.id;
            })
            .join(',');
      let data = [];
      if (row) {
        data.push(row);
      } else {
        data = this.detailListSelections;
      }
      this.periodTable = data.map((item) => {
        return item.periodWithTimeName;
      });
      this.orgName = data[0].orgName;
    },
  },
};
</script>
